import React from "react";
import PublisherBox from "./components/publisher_box";
 
const Publish = () => {
    return (
        <div>
            <h1>
                CDN Publisher
            </h1>
            <PublisherBox></PublisherBox>
        </div>
    );
};
 
export default Publish;