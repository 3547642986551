import React, { Component } from 'react';

class About extends Component {
    state = {  } 
    render() { 
        return (
            <div className='text-center'>
                <h1 className='mt-3'>BRYCE NATTER</h1>
                <p>Hey, I'm Bryce! Check out what I've been up to!</p>
            </div>
        );
    }
}
 
export default About;